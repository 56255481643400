import React from 'react'
import { graphql } from 'gatsby'
import styles from './collection.module.scss'
import NavLayout from '../components/NavLayout.js'
import PageTitle from '../components/PageTitle.js'
import ImageGrid from '../components/ImageGrid.js'
import Media from 'react-media'

export default ({ data }) => {
  let { Title, Description, paintings } = data.strapiCollection

  const sortPaintings = paintings => {
    const paintingsWithUnix = paintings.map(painting => {
      painting.Date = new Date(painting.Date).getTime()
      return painting
    })
    const sorted = paintingsWithUnix.sort((a, b) => b.Date - a.Date)
    return sorted.filter(painting => !painting.Hide)
  }

  const sortedPaintings = sortPaintings(paintings)

  return (
    <NavLayout>
      <PageTitle title={Title} />
      <p className={styles.description}>{Description}</p>

      <Media query="(min-width:500px)">
        {matches => (
          <ImageGrid
            images={sortedPaintings}
            numberOfColumns={matches ? 3 : 1}
            route={Title}
          />
        )}
      </Media>
    </NavLayout>
  )
}

export const query = graphql`
  query($id: String!) {
    strapiCollection(id: { eq: $id }) {
      Title
      Description
      paintings {
        Title
        Medium
        Height
        Width
        Sold
        Hide
        id
        Date
        Image {
          id
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }
`
